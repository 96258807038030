define("ember-place-autocomplete/instance-initializers/google", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(appInstance) {
    if (window && window.google) {
      appInstance.register('google:main', window.google, {
        instantiate: false
      });
    } else {
      console.debug('[warning] ember-place-autocomplete: window.google not found on initialize, google:main registration will be re-attempted on first use.');
    }
  }

  var _default = {
    name: 'register-google',
    initialize
  };
  _exports.default = _default;
});