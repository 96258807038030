define("ember-svg-jar/inlined/half-smile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"10.75\" stroke=\"#F0EAE1\" stroke-width=\"2.5\"/><path d=\"M16.8 12a4.8 4.8 0 11-9.6 0\" stroke=\"#F0EAE1\" stroke-width=\"2.5\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});