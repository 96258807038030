define("ember-cli-meta-tags/mixins/route-meta", ["exports", "@ember/runloop", "@ember/service", "@ember/object/mixin", "@ember/array"], function (_exports, _runloop, _service, _mixin, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.metaToHeadTags = metaToHeadTags;

  // Route mixin for setting head meta tags on transition into/out of route
  // @example How to set meta tags on a route
  //   ExampleRoute = Ember.Route.extend RouteMetaMixin,
  //     meta: ->
  //       return
  //         meta_property_name1: meta_value1
  //         meta_property_name2: meta_value2
  function metaToHeadTags(meta) {
    let metaTypes = Object.keys(meta);
    return metaTypes.reduce(function (headTags, meta_type) {
      return headTags.pushObjects(Object.keys(meta[meta_type]).map(function (key) {
        return {
          tagId: `${meta_type}:${key}`,
          type: 'meta',
          attrs: {
            [meta_type]: key,
            content: meta[meta_type][key]
          }
        };
      }));
    }, (0, _array.A)([]));
  }

  var _default = _mixin.default.create({
    headTagsService: (0, _service.inject)('head-tags'),

    // convert legacy meta tags to headTags
    headTags() {
      let meta = this.get('meta');

      if (typeof meta === 'function') {
        meta = meta.apply(this);
      } else if (typeof meta !== 'object') {
        return undefined;
      }

      return metaToHeadTags(meta);
    },

    actions: {
      resetMeta() {
        let service = this.get('headTagsService');
        (0, _runloop.next)(service, 'collectHeadTags');
      }

    }
  });

  _exports.default = _default;
});