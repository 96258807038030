define("ember-form-for/components/form-fields/radio-group", ["exports", "@ember/component", "@ember/object", "ember-form-for/templates/components/form-fields/radio-group"], function (_exports, _component, _object, _radioGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const RadioGroupComponent = _component.default.extend({
    tagName: '',
    layout: _radioGroup.default,

    update() {
      (0, _object.set)(...arguments);
    }

  });

  RadioGroupComponent.reopenClass({
    positionalParams: ['propertyName']
  });
  var _default = RadioGroupComponent;
  _exports.default = _default;
});