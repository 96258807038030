define("ember-validated-form/components/validated-input/types/textarea", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component"], function (_exports, _component, _templateFactory, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <textarea
    class={{class-list
      (if
        (macroCondition (macroGetOwnConfig "isUikit"))
        (class-list
          "uk-textarea"
          (if @isValid "uk-form-success")
          (if @isInvalid "uk-form-danger")
        )
      )
      (if
        (macroCondition (macroGetOwnConfig "isBootstrap"))
        (class-list
          "form-control" (if @isValid "is-valid") (if @isInvalid "is-invalid")
        )
      )
    }}
    {{on "input" this.onUpdate}}
    {{on "blur" @setDirty}}
    ...attributes
  >
  </textarea>
  */
  {
    "id": "V1k1+69w",
    "block": "[[[11,\"textarea\"],[16,0,[28,[37,0],[[28,[37,0],[\"uk-textarea\",[52,[30,1],\"uk-form-success\"],[52,[30,2],\"uk-form-danger\"]],null],[27]],null]],[17,3],[4,[38,2],[\"input\",[30,0,[\"onUpdate\"]]],null],[4,[38,2],[\"blur\",[30,4]],null],[12],[1,\"\"],[13]],[\"@isValid\",\"@isInvalid\",\"&attrs\",\"@setDirty\"],false,[\"class-list\",\"if\",\"on\"]]",
    "moduleName": "ember-validated-form/components/validated-input/types/textarea.hbs",
    "isStrictMode": false
  });

  let TextareaComponent = (_class = class TextareaComponent extends _component2.default {
    onUpdate(event) {
      event.preventDefault();
      this.args.update(event.target.value);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype)), _class);
  _exports.default = TextareaComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TextareaComponent);
});