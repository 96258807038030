define("ember-power-calendar/components/power-calendar-range", ["exports", "@ember/object", "ember-power-calendar/components/power-calendar", "ember-power-calendar/utils/computed-fallback-if-undefined", "ember-power-calendar-utils", "@ember/debug", "ember-power-calendar/-private/utils/own-prop"], function (_exports, _object, _powerCalendar, _computedFallbackIfUndefined, _emberPowerCalendarUtils, _debug, _ownProp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_dec = (0, _computedFallbackIfUndefined.default)(false), _dec2 = (0, _object.computed)('center'), _dec3 = (0, _object.computed)('_publicAPI', 'minRange', 'maxRange'), (_class2 = class _class2 extends _powerCalendar.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "proximitySelection", _descriptor, this);

      _defineProperty(this, "daysComponent", 'power-calendar-range/days');

      _defineProperty(this, "_calendarType", 'range');
    }

    // CPs
    get minRange() {
      return 86400000;
    }

    set minRange(v) {
      if (typeof v === 'number') {
        return v * 86400000;
      }

      return (0, _emberPowerCalendarUtils.normalizeDuration)(v === undefined ? 86400000 : v);
    }

    get maxRange() {
      return null;
    }

    set maxRange(v) {
      if (typeof v === 'number') {
        return v * 86400000;
      }

      return (0, _emberPowerCalendarUtils.normalizeDuration)(v === undefined ? 86400000 : v);
    }

    get selected() {
      return {
        start: undefined,
        end: undefined
      };
    }

    set selected(v) {
      if (v === undefined || v === null) {
        v = {};
      }

      return {
        start: (0, _emberPowerCalendarUtils.normalizeDate)(v.start),
        end: (0, _emberPowerCalendarUtils.normalizeDate)(v.end)
      };
    }

    get currentCenter() {
      let center = this.center;

      if (!center) {
        center = this.selected.start || this.powerCalendarService.getDate();
      }

      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    }

    get publicAPI() {
      let rangeOnlyAPI = this.getProperties('minRange', 'maxRange');
      return Object.assign(rangeOnlyAPI, this._publicAPI);
    } // Actions


    select(_ref, calendar, e) {
      let {
        date
      } = _ref;
      (false && !(date && ((0, _ownProp.default)(date, 'start') || (0, _ownProp.default)(date, 'end') || date instanceof Date)) && (0, _debug.assert)('date must be either a Date, or a Range', date && ((0, _ownProp.default)(date, 'start') || (0, _ownProp.default)(date, 'end') || date instanceof Date)));
      let range;

      if ((0, _ownProp.default)(date, 'start') && (0, _ownProp.default)(date, 'end')) {
        range = {
          date
        };
      } else {
        range = this._buildRange({
          date
        });
      }

      let {
        start,
        end
      } = range.date;

      if (start && end) {
        let {
          minRange,
          maxRange
        } = this.publicAPI;
        let diffInMs = Math.abs((0, _emberPowerCalendarUtils.diff)(end, start));

        if (diffInMs < minRange || maxRange && diffInMs > maxRange) {
          return;
        }
      }

      if (this.onSelect) {
        this.onSelect(range, calendar, e);
      }
    } // Methods


    _buildRange(day) {
      let selected = this.publicAPI.selected || {
        start: null,
        end: null
      };
      let {
        start,
        end
      } = (0, _object.getProperties)(selected, 'start', 'end');

      if (this.proximitySelection) {
        return this._buildRangeByProximity(day, start, end);
      }

      return this._buildDefaultRange(day, start, end);
    }

    _buildRangeByProximity(day, start, end) {
      if (start && end) {
        let changeStart = Math.abs((0, _emberPowerCalendarUtils.diff)(day.date, end)) > Math.abs((0, _emberPowerCalendarUtils.diff)(day.date, start));
        return (0, _emberPowerCalendarUtils.normalizeRangeActionValue)({
          date: {
            start: changeStart ? day.date : start,
            end: changeStart ? end : day.date
          }
        });
      }

      if ((0, _emberPowerCalendarUtils.isBefore)(day.date, start)) {
        return (0, _emberPowerCalendarUtils.normalizeRangeActionValue)({
          date: {
            start: day.date,
            end: null
          }
        });
      }

      return this._buildDefaultRange(day, start, end);
    }

    _buildDefaultRange(day, start, end) {
      if (start && !end) {
        if ((0, _emberPowerCalendarUtils.isAfter)(start, day.date)) {
          return (0, _emberPowerCalendarUtils.normalizeRangeActionValue)({
            date: {
              start: day.date,
              end: start
            }
          });
        }

        return (0, _emberPowerCalendarUtils.normalizeRangeActionValue)({
          date: {
            start: start,
            end: day.date
          }
        });
      }

      return (0, _emberPowerCalendarUtils.normalizeRangeActionValue)({
        date: {
          start: day.date,
          end: null
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "proximitySelection", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "minRange", [_object.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "minRange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "maxRange", [_object.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "maxRange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selected", [_object.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "selected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "currentCenter", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "currentCenter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "publicAPI", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "publicAPI"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "select"), _class2.prototype)), _class2));

  _exports.default = _class;
});