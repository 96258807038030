define("ember-form-for/components/form-fields/checkbox-group", ["exports", "ember-form-for/templates/components/form-fields/checkbox-group", "@ember/component", "@ember/object"], function (_exports, _checkboxGroup, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const CheckboxGroupComponent = _component.default.extend({
    tagName: '',
    layout: _checkboxGroup.default,
    actions: {
      updateSelection(value, object, propertyName, include) {
        let selection = (0, _object.get)(object, propertyName);

        if (include && !selection.includes(value)) {
          selection.pushObject(value);
        } else {
          selection.removeObject(value);
        }

        if (this.update !== undefined) {
          this.update(object, propertyName, selection);
        }
      }

    }
  });

  CheckboxGroupComponent.reopenClass({
    positionalParams: ['propertyName']
  });
  var _default = CheckboxGroupComponent;
  _exports.default = _default;
});