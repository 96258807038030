define("ember-form-for/components/form-fields/textarea-field", ["exports", "@ember/component", "@ember/object", "ember-form-for/templates/components/form-fields/textarea-field"], function (_exports, _component, _object, _textareaField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const TextareaFieldComponent = _component.default.extend({
    tagName: '',
    layout: _textareaField.default,
    control: 'one-way-textarea',

    update(object, propertyName, value) {
      (0, _object.set)(object, propertyName, value);
    }

  });

  TextareaFieldComponent.reopenClass({
    positionalParams: ['propertyName']
  });
  var _default = TextareaFieldComponent;
  _exports.default = _default;
});