define("ember-form-for/helpers/form-for/merge-custom-form-field", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formForMergeCustomFormField = formForMergeCustomFormField;

  function formForMergeCustomFormField(_ref, _ref2) {
    let [formFieldsHash] = _ref;
    let {
      name,
      component
    } = _ref2;
    formFieldsHash[name] = component;
  }

  var _default = (0, _helper.helper)(formForMergeCustomFormField);

  _exports.default = _default;
});