define("ember-form-for/components/form-fields/datetime-local-field", ["exports", "ember-form-for/components/form-fields/text-field", "ember-form-for/utils/date"], function (_exports, _textField, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _textField.default.extend({
    control: 'one-way-datetime-local',

    serializeValue(value) {
      if (value instanceof Date) {
        return (0, _date.toDatetimeLocalString)(value);
      }

      return value;
    },

    deserializeValue(value) {
      if (value != null) {
        let offset = new Date(value).getTimezoneOffset();
        return new Date(value + (0, _date.formatTimeZoneOffset)(offset));
      }

      return value;
    }

  });

  _exports.default = _default;
});