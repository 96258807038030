define("ember-form-for/components/form-fields/date-field", ["exports", "@ember/utils", "ember-form-for/utils/date", "ember-form-for/components/form-fields/text-field"], function (_exports, _utils, _date, _textField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _textField.default.extend({
    control: 'one-way-date',

    serializeValue(value) {
      if (value instanceof Date) {
        return (0, _date.toDateString)(value);
      }

      return value;
    },

    deserializeValue(value) {
      if ((0, _utils.isEmpty)(value)) {
        return null;
      }

      return new Date(value);
    }

  });

  _exports.default = _default;
});