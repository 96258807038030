define('ember-math-helpers/helpers/pow', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pow = pow;
  function pow(params) {
    return params.reduce((base, exponent) => Math.pow(base, exponent));
  }

  exports.default = Ember.Helper.helper(pow);
});