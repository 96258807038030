define("ember-infinity/components/infinity-loader", ["exports", "@ember/runloop", "@ember/object", "@ember/component", "@ember/service", "ember-infinity/templates/components/infinity-loader"], function (_exports, _runloop, _object, _component, _service, _infinityLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const InfinityLoaderComponent = _component.default.extend({
    layout: _infinityLoader.default,
    infinity: (0, _service.inject)(),
    inViewport: (0, _service.inject)(),
    tagName: '',

    /**
     * @public
     * @property eventDebounce
     * @default 50
     */
    eventDebounce: 50,

    /**
     * @public
     * @property loadingText
     */
    loadingText: 'Loading Infinity Model...',

    /**
     * @public
     * @property loadedText
     */
    loadedText: 'Infinity Model Entirely Loaded.',

    /**
     * @public
     * @property hideOnInfinity
     * @default false
     */
    hideOnInfinity: false,

    /**
     * @public
     * @property isDoneLoading
     * @default false
     */
    isDoneLoading: false,

    /**
     * @public
     * @property developmentMode
     * @default false
     */
    developmentMode: false,

    /**
     * indicate to infinity-loader to load previous page
     *
     * @public
     * @property loadPrevious
     * @default false
     */
    loadPrevious: false,

    /**
     * set if have scrollable area
     *
     * @public
     * @property scrollable
     */
    scrollable: null,

    /**
     * offset from bottom of target and viewport
     *
     * @public
     * @property triggerOffset
     * @defaul 0
     */
    triggerOffset: 0,

    /**
     * https://emberjs.com/api/ember/3.0/classes/Component/properties/isVisible?anchor=isVisible
     *
     * @property isVisible
     */
    isVisible: true,
    loaderClassNames: (0, _object.computed)('classNames', function () {
      return 'infinity-loader '.concat(this.classNames).trim();
    }),

    init() {
      this._super(...arguments);

      (0, _object.defineProperty)(this, 'infinityModelContent', (0, _object.computed)('infinityModel', function () {
        return Promise.resolve(this.infinityModel);
      }));
      this.addObserver('infinityModel', this, this._initialInfinityModelSetup);

      this._initialInfinityModelSetup();

      this.addObserver('hideOnInfinity', this, this._loadStatusDidChange);
      this.addObserver('reachedInfinity', this, this._loadStatusDidChange);
    },

    /**
     * setup ember-in-viewport properties
     *
     * @method didInsertElement
     */
    didInsertLoader(element, _ref) {
      let [instance] = _ref;

      /**
       * @public
       * @property loadingText
       */
      (0, _object.set)(instance, 'loadingText', instance.loadingText || 'Loading Infinity Model...');
      /**
       * @public
       * @property loadedText
       */

      (0, _object.set)(instance, 'loadedText', instance.loadedText || 'Infinity Model Entirely Loaded.');
      instance.elem = element;
      let options = {
        viewportSpy: true,
        viewportTolerance: {
          top: 0,
          right: 0,
          bottom: instance.triggerOffset,
          left: 0
        },
        scrollableArea: instance.scrollable
      };
      const {
        onEnter,
        onExit
      } = instance.inViewport.watchElement(element, options);
      onEnter(instance.didEnterViewport.bind(instance));
      onExit(instance.didExitViewport.bind(instance));
    },

    willDestroy() {
      this._cancelTimers();

      (0, _object.get)(this, 'infinityModelContent').then(infinityModel => {
        infinityModel.off('infinityModelLoaded', this, this._loadStatusDidChange.bind(this));
      });
      this.removeObserver('infinityModel', this, this._initialInfinityModelSetup);
      this.removeObserver('hideOnInfinity', this, this._loadStatusDidChange);
      this.removeObserver('reachedInfinity', this, this._loadStatusDidChange);
    },

    /**
     * https://github.com/DockYard/ember-in-viewport#didenterviewport-didexitviewport
     *
     * @method didEnterViewport
     */
    didEnterViewport() {
      if (this.developmentMode || typeof FastBoot !== 'undefined' || this.isDestroying || this.isDestroyed) {
        return false;
      }

      if ((0, _object.get)(this, 'loadPrevious')) {
        return this._debounceScrolledToTop();
      }

      return this._debounceScrolledToBottom();
    },

    /**
     * https://github.com/DockYard/ember-in-viewport#didenterviewport-didexitviewport
     *
     * @method didExitViewport
     */
    didExitViewport() {
      this._cancelTimers();
    },

    /**
     * @method _initialInfinityModelSetup
     */
    _initialInfinityModelSetup() {
      (0, _object.get)(this, 'infinityModelContent').then(infinityModel => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        infinityModel.on('infinityModelLoaded', this._loadStatusDidChange.bind(this));
        (0, _object.set)(infinityModel, '_scrollable', (0, _object.get)(this, 'scrollable'));
        (0, _object.set)(this, 'isDoneLoading', false);

        if (!(0, _object.get)(this, 'hideOnInfinity')) {
          (0, _object.set)(this, 'isVisible', true);
        }

        this._loadStatusDidChange();
      });
    },

    /**
     * @method _loadStatusDidChange
     */
    _loadStatusDidChange() {
      (0, _object.get)(this, 'infinityModelContent').then(infinityModel => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        if ((0, _object.get)(infinityModel, 'reachedInfinity')) {
          (0, _object.set)(this, 'isDoneLoading', true);

          if ((0, _object.get)(this, 'hideOnInfinity')) {
            (0, _object.set)(this, 'isVisible', false);
          }
        } else {
          (0, _object.set)(this, 'isVisible', true);
        }
      });
    },

    /**
     * only load previous page if route started on a page greater than 1 && currentPage is > 0
     *
     * @method _debounceScrolledToTop
     */
    _debounceScrolledToTop() {
      /*
       This debounce is needed when there is not enough delay between onScrolledToBottom calls.
       Without this debounce, all rows will be rendered causing immense performance problems
       */
      function loadPreviousPage(content) {
        if (typeof (0, _object.get)(this, 'infinityLoad') === 'function') {
          // closure action
          return (0, _object.get)(this, 'infinityLoad')(content, -1);
        } else {
          (0, _object.get)(this, 'infinity').infinityLoad(content, -1);
        }
      }

      (0, _object.get)(this, 'infinityModelContent').then(content => {
        if ((0, _object.get)(content, 'firstPage') > 1 && (0, _object.get)(content, 'currentPage') > 0) {
          this._debounceTimer = (0, _runloop.debounce)(this, loadPreviousPage, content, (0, _object.get)(this, 'eventDebounce'));
        }
      });
    },

    /**
     * @method _debounceScrolledToBottom
     */
    _debounceScrolledToBottom() {
      /*
       This debounce is needed when there is not enough delay between onScrolledToBottom calls.
       Without this debounce, all rows will be rendered causing immense performance problems
       */
      function loadMore() {
        // resolve to create thennable
        // type is <InfinityModel|Promise|null>
        (0, _object.get)(this, 'infinityModelContent').then(content => {
          if (typeof (0, _object.get)(this, 'infinityLoad') === 'function') {
            // closure action (if you need to perform some other logic)
            return (0, _object.get)(this, 'infinityLoad')(content);
          } else {
            // service action
            (0, _object.get)(this, 'infinity').infinityLoad(content, 1).then(() => {
              if ((0, _object.get)(content, 'canLoadMore')) {
                this._checkScrollableHeight();
              }
            });
          }
        });
      }

      this._debounceTimer = (0, _runloop.debounce)(this, loadMore, (0, _object.get)(this, 'eventDebounce'));
    },

    /**
     * recursive function to fill page with records
     *
     * @method _checkScrollableHeight
     */
    _checkScrollableHeight() {
      if (this.isDestroying || this.isDestroyed) {
        return false;
      }

      if (this._viewportBottom() > this.elem.getBoundingClientRect().top) {
        // load again
        this._debounceScrolledToBottom();
      }
    },

    /**
     * @method _cancelTimers
     */
    _cancelTimers() {
      (0, _runloop.cancel)(this._debounceTimer);
    },

    /**
      calculate the bottom of the viewport
       @private
      @method _viewportBottom
      @return Integer
     */
    _viewportBottom() {
      if (typeof FastBoot === 'undefined') {
        let isScrollable = !!this.scrollable;
        let viewportElem = isScrollable ? document.querySelector(this.scrollable) : window;
        return isScrollable ? viewportElem.getBoundingClientRect().bottom : viewportElem.innerHeight;
      }
    }

  });

  var _default = InfinityLoaderComponent;
  _exports.default = _default;
});