define("ember-form-for/templates/components/form-controls/submit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iWlNivv6",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[30,0],[33,3]]],[1,\"\\n\"]],[]],[[[41,[33,3,[\"isPending\"]],[[[1,\"    \"],[1,[52,[33,4],[33,4],[33,5]]],[1,\"\\n\"]],[]],[[[41,[33,3,[\"isFulfilled\"]],[[[1,\"    \"],[1,[52,[33,6],[33,6],[33,5]]],[1,\"\\n\"]],[]],[[[41,[33,3,[\"isRejected\"]],[[[1,\"    \"],[1,[52,[33,7],[33,7],[33,5]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[34,5]],[1,\"\\n  \"]],[]]]],[]]]],[]]]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"activePromise\",\"pending\",\"default\",\"fulfilled\",\"rejected\"]]",
    "moduleName": "ember-form-for/templates/components/form-controls/submit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});