define("ember-form-for/helpers/contains", ["exports", "@ember/array", "@ember/component/helper"], function (_exports, _array, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;

  function contains(_ref) {
    let [haystack, needle] = _ref;

    if ((0, _array.isArray)(haystack)) {
      return (0, _array.A)(haystack).includes(needle);
    } else {
      return haystack === needle;
    }
  }

  var _default = (0, _helper.helper)(contains);

  _exports.default = _default;
});