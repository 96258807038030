define("ember-svg-jar/inlined/computer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" fill=\"#fff\"><rect width=\"25.5\" height=\"19.125\" rx=\"2\"/></mask><rect width=\"25.5\" height=\"19.125\" rx=\"2\" stroke=\"#E60D1B\" stroke-width=\"5\" mask=\"url(#a)\"/><path stroke=\"#E60D1B\" stroke-width=\"2.5\" d=\"M12.406 17.531v4.782M3.187 24.25h19.126\"/>",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});