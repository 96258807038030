define("ember-svg-jar/inlined/arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M24.707 8.303a1 1 0 000-1.414L18.343.525a1 1 0 10-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 8.596h24v-2H0v2z\" fill=\"#1566E7\"/>",
    "attrs": {
      "width": "25",
      "height": "15",
      "viewBox": "0 0 25 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});