define("ember-form-for/components/form-fields/search-field", ["exports", "ember-form-for/components/form-fields/text-field"], function (_exports, _textField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _textField.default.extend({
    control: 'one-way-search'
  });

  _exports.default = _default;
});