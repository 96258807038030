define("ember-api-actions/utils/collection-action", ["exports", "@ember/polyfills", "ember-api-actions/utils/build-url", "ember-api-actions/utils/types"], function (_exports, _polyfills, _buildUrl, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = collectionOp;

  function collectionOp(options) {
    return function runCollectionOp(payload) {
      const model = this;
      const recordClass = (0, _buildUrl._getModelClass)(model);
      const modelName = (0, _buildUrl._getModelName)(recordClass);
      const store = (0, _buildUrl._getStoreFromRecord)(model);
      const requestType = (0, _types.strictifyHttpVerb)(options.type || 'put');
      const urlType = options.urlType || 'updateRecord';
      const adapter = store.adapterFor(modelName);
      const fullUrl = (0, _buildUrl.buildOperationUrl)(model, options.path, urlType, false);
      const data = options.before && options.before.call(model, payload) || payload;
      return adapter.ajax(fullUrl, requestType, (0, _polyfills.assign)(options.ajaxOptions || {}, {
        data
      })).then(response => {
        if (options.after && !model.isDestroyed) {
          return options.after.call(model, response);
        }

        return response;
      });
    };
  }
});