define("ember-cli-string-helpers/-private/create-string-helper", ["exports", "@ember/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(stringFunction) {
    return function (_ref) {
      let [string] = _ref;

      if ((0, _string.isHTMLSafe)(string)) {
        string = string.string;
      }

      string = string || '';
      return stringFunction(string);
    };
  }
});