define('ember-math-helpers/helpers/max', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.max = max;
  function max(params) {
    return Math.max(...params);
  }

  exports.default = Ember.Helper.helper(max);
});