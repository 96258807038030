define("ember-form-for/templates/components/form-fields/radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3a7m9dLK",
    "block": "[[[6,[39,0],[[33,1]],[[\"tagName\",\"class\",\"classNames\",\"errorClasses\",\"hintClasses\",\"inputClasses\",\"labelClasses\",\"object\",\"hint\",\"form\",\"label\"],[\"fieldset\",\"radio-group\",[33,2],[33,3],[33,4],[33,5],[33,6],[33,7],[33,8],[33,9],[33,10]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,11],[[30,1,[\"labelText\"]]],[[\"required\",\"tagName\"],[[33,12],\"legend\"]]]],[1,\"\\n\\n  \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,14],[[28,[37,14],[[33,15]],null]],null],null,[[[1,\"      \"],[1,[28,[35,16],[[33,1],[30,2]],[[\"disabled\",\"tagName\",\"object\",\"update\"],[[33,17],\"li\",[33,7],[28,[37,18],[[30,0],[33,19]],null]]]]],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[1,[30,1,[\"errors\"]]],[1,\"\\n  \"],[1,[30,1,[\"hint\"]]],[1,\"\\n\"]],[1]]]]]],[\"f\",\"option\"],false,[\"form-field\",\"propertyName\",\"fieldClasses\",\"errorClasses\",\"hintClasses\",\"inputClasses\",\"labelClasses\",\"object\",\"hint\",\"form\",\"label\",\"form-label\",\"required\",\"each\",\"-track-array\",\"options\",\"form-fields/radio-field\",\"disabled\",\"action\",\"update\"]]",
    "moduleName": "ember-form-for/templates/components/form-fields/radio-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});