define("ember-form-for/components/form-hint", ["exports", "@ember/component", "@ember/object", "ember-form-for/templates/components/form-hint"], function (_exports, _component, _object, _formHint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    layout: _formHint.default,
    joinedHintClasses: (0, _object.computed)('hintClasses', function () {
      return (this.hintClasses || []).join(' ');
    })
  });

  _exports.default = _default;
});