define("ember-form-for/components/form-controls/submit", ["exports", "@ember/object", "@ember/object/computed", "@ember/object/promise-proxy-mixin", "ember-form-for/templates/components/form-controls/submit", "rsvp", "ember-form-for/components/form-controls/button"], function (_exports, _object, _computed, _promiseProxyMixin, _submit, _rsvp, _button) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const SubmitButton = _button.default.extend({
    layout: _submit.default,
    tagName: 'button',
    type: 'submit',
    activePromise: undefined,
    classNames: ['async-button'],
    attributeBindings: ['disabled', 'type'],
    submit: (0, _computed.alias)('action'),
    default: 'Submit',
    pending: 'Submitting...',
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    NON_ATTRIBUTE_BOUND_PROPS: ['click'],

    init() {
      this._super(...arguments);
    },

    click() {
      // PromiseProxyMixin allows us to use .isPending in the templates
      // RSVP.Promise is required to handle situation when submit function
      // returns non-promise.
      this.set('activePromise', _object.default.extend(_promiseProxyMixin.default).create({
        promise: new _rsvp.default.Promise(resolve => {
          resolve(this.submit());
        })
      }));
      return false;
    },

    disabled: (0, _object.computed)('disableButton', 'activePromise.isPending', function () {
      if (this.disableButton == true) {
        return true;
      } else if (this.get('activePromise.isPending') === true) {
        return true;
      } else {
        return false;
      }
    }),
    // eslint-disable-next-line ember/no-observers
    resetAction: (0, _object.observer)('reset', 'activePromise.isFulfilled', 'activePromise.isRejected', function () {
      if (this.reset && (this.get('activePromise.isFulfilled') || this.get('activePromise.isRejected'))) {
        this.set('activePromise', undefined);
      }
    })
  });

  SubmitButton.reopenClass({
    positionalParams: ['default']
  });
  var _default = SubmitButton;
  _exports.default = _default;
});