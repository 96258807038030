define("ember-form-for/components/form-for/custom-tag", ["exports", "@ember/component", "ember-form-for/templates/components/form-for/custom-tag"], function (_exports, _component, _customTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _customTag.default,
    attributeBindings: ['id', 'role']
  });

  _exports.default = _default;
});