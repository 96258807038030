define("ember-form-for/components/form-controls/button", ["exports", "@ember/component", "@ember/object", "@ember/service", "ember-one-way-controls/-private/dynamic-attribute-bindings", "ember-form-for/templates/components/form-controls/button"], function (_exports, _component, _object, _service, _dynamicAttributeBindings, _button) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Button = _component.default.extend(_dynamicAttributeBindings.default, {
    layout: _button.default,
    tagName: 'button',
    type: 'button',
    attributeBindings: ['type'],
    config: (0, _service.inject)('ember-form-for/config'),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    NON_ATTRIBUTE_BOUND_PROPS: ['click'],

    init() {
      this._super(...arguments);

      let type = this.type;
      let buttonClasses = (0, _object.get)(this, `config.${type}Classes`); // @todo: this is bad practice, it should be fixed

      let classNames = this.classNames;
      (0, _object.set)(this, 'classNames', (classNames || []).concat(buttonClasses));
    }

  });

  Button.reopenClass({
    positionalParams: ['label']
  });
  var _default = Button;
  _exports.default = _default;
});