define("ember-in-viewport/utils/is-in-viewport", ["exports", "@ember/polyfills"], function (_exports, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isInViewport;
  const defaultTolerance = {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  };

  function isInViewport() {
    let boundingClientRect = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let height = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    let width = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    let tolerance = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : defaultTolerance;
    const {
      top,
      left,
      bottom,
      right,
      height: h,
      width: w
    } = boundingClientRect;
    const tolerances = (0, _polyfills.assign)((0, _polyfills.assign)({}, defaultTolerance), tolerance);
    const {
      top: topTolerance,
      left: leftTolerance,
      bottom: bottomTolerance,
      right: rightTolerance
    } = tolerances;
    return top + topTolerance >= 0 && left + leftTolerance >= 0 && Math.round(bottom) - bottomTolerance - h <= Math.round(height) && Math.round(right) - rightTolerance - w <= Math.round(width);
  }
});