define("ember-form-for/templates/components/form-fields/checkbox-group/option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sSghCmF+",
    "block": "[[[6,[39,0],[[33,1]],[[\"control\",\"tagName\",\"object\",\"formName\",\"update\"],[\"one-way-checkbox\",\"li\",[33,2],[33,3],[33,4]]],[[\"default\"],[[[[6,[30,1,[\"label\"]],null,null,[[\"default\"],[[[[1,\"    \"],[1,[28,[30,1,[\"control\"]],[[28,[37,5],[[28,[37,6],[[33,2],[33,1]],null],[33,7]],null]],[[\"disabled\",\"dir\",\"lang\",\"value\"],[[33,8],[33,9],[33,10],[33,7]]]]],[1,\"\\n    \"],[1,[34,11]],[1,\"\\n\"]],[]]]]]],[1]]]]]],[\"fc\"],false,[\"form-field\",\"propertyName\",\"object\",\"formName\",\"update\",\"one-way-select/contains\",\"get\",\"option\",\"disabled\",\"dir\",\"lang\",\"labelText\"]]",
    "moduleName": "ember-form-for/templates/components/form-fields/checkbox-group/option.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});