define("ember-form-for/components/form-controls/reset", ["exports", "ember-form-for/components/form-controls/button"], function (_exports, _button) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _button.default.extend({
    type: 'reset',

    click(e) {
      e.preventDefault();

      if (this.reset !== undefined) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        this.reset(...args);
      }
    }

  });

  _exports.default = _default;
});