define("ember-form-for/components/form-field", ["exports", "@ember/component", "@ember/debug", "@ember/object", "@ember/object/computed", "@ember/object/internals", "@ember/service", "@ember/string", "@ember/utils", "ember", "ember-form-for/templates/components/form-field", "ember-form-for/utils/strings"], function (_exports, _component, _debug, _object, _computed, _internals, _service, _string, _utils, _ember, _formField, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    mixin
  } = _ember.default;

  const FormFieldComponent = _component.default.extend({
    layout: _formField.default,
    config: (0, _service.inject)('ember-form-for/config'),
    _defaultErrorsProperty: 'errors',
    errorsProperty: (0, _computed.or)('config.errorsProperty', '_defaultErrorsProperty'),

    errorsPath(propertyName) {
      let errorsPath = this.get('config.errorsPath');
      let errorsProperty = this.errorsProperty;

      if (!(0, _utils.isPresent)(errorsPath)) {
        errorsPath = `${errorsProperty}.PROPERTY_NAME`;
      }

      return errorsPath.replace('PROPERTY_NAME', propertyName);
    },

    classNameBindings: [],
    concatenatedProperties: ['inputClasses', 'labelClasses', 'hintClasses', 'errorClasses'],
    control: 'one-way-input',

    init() {
      this._super(...arguments);

      let fieldClasses = (0, _object.get)(this, 'config.fieldClasses');
      this.classNames = this.classNames.concat(fieldClasses);
      this.classNameBindings = this.classNameBindings.slice();
      this.classNameBindings.push(`hasErrors:${(0, _object.get)(this, 'config.fieldHasErrorClasses')}`);
      this.propertyNameDidChange();
    },

    didReceiveAttrs() {
      this._super(...arguments);

      ['inputClasses', 'labelClasses', 'hintClasses', 'errorClasses'].forEach(type => {
        (0, _object.set)(this, `_${type}`, ((0, _object.get)(this, type) || []).concat((0, _object.get)(this, `config.${type}`)));
      });
      (false && !(this.object != null) && (0, _debug.assert)('{{form-field}} requires an object property to be passed in', this.object != null));
      (false && !(typeof this.propertyName === 'string') && (0, _debug.assert)('{{form-field}} requires the propertyName property to be set', typeof this.propertyName === 'string'));
      (0, _object.set)(this, 'modelName', this.getModelName());
    },

    // eslint-disable-next-line ember/no-observers
    propertyNameDidChange: (0, _object.observer)('propertyName', 'errorsProperty', function () {
      let propertyName = this.propertyName;
      let errorsPath = `object.${this.errorsPath(propertyName)}`;
      mixin(this, {
        rawValue: (0, _computed.reads)(`object.${propertyName}`),
        errors: (0, _computed.reads)(errorsPath),
        hasErrors: (0, _computed.notEmpty)(errorsPath)
      });
    }),

    update(object, propertyName, value) {
      (0, _object.set)(object, propertyName, value);
    },

    labelText: (0, _object.computed)('i18n.locale', 'label', 'labelI18nKey', 'propertyName', function () {
      let i18n = this.i18n;
      let label = this.label;

      if ((0, _utils.isPresent)(label)) {
        return label;
      } else if ((0, _utils.isPresent)(i18n)) {
        return i18n.t(this.labelI18nKey);
      } else {
        return (0, _strings.humanize)(this.propertyName);
      }
    }),
    labelI18nKey: (0, _object.computed)('config.i18nKeyPrefix', 'modelName', 'propertyName', function () {
      return [(0, _object.get)(this, 'config.i18nKeyPrefix'), (0, _string.dasherize)(this.modelName || ''), (0, _string.dasherize)(this.propertyName || '')].filter(x => !!x).join('.');
    }),
    fieldId: (0, _object.computed)('elementId', 'form', 'object', 'propertyName', function () {
      let baseId = this.form || this.elementId;
      return `${baseId}_${this.propertyName}`;
    }),
    fieldName: (0, _object.computed)('object', 'modelName', 'propertyName', function () {
      return `${this._nameForObject()}[${this.propertyName}]`;
    }),
    describedByValue: (0, _object.computed)('hint', 'errors.[]', 'fieldId', function () {
      let ids = [];
      let hint = this.hint;
      let errors = this.errors;
      let fieldId = this.fieldId;

      if ((0, _utils.isPresent)(hint)) {
        ids.push(`${fieldId}_hint`);
      }

      if ((0, _utils.isPresent)(errors)) {
        errors.forEach((_, index) => {
          ids.push(`${fieldId}_error-${index}`);
        });
      }

      return (0, _utils.isEmpty)(ids) ? null : ids.join(' ');
    }),

    _nameForObject() {
      return this.modelName || (0, _internals.guidFor)(this.object);
    },

    getModelName() {
      let formName = this.form;
      let modelName = (0, _object.get)(this, 'object.modelName');
      let constructorName = (0, _object.get)(this, 'object.constructor.modelName');
      let changesetConstructorName = (0, _object.get)(this, 'object._content.constructor.modelName');
      return formName || modelName || constructorName || changesetConstructorName;
    },

    value: (0, _object.computed)('rawValue', 'serializeValue', function () {
      let serializeValue = this.serializeValue === undefined ? value => value : this.serializeValue;
      return serializeValue(this.rawValue);
    }),
    actions: {
      processUpdate(object, propertyName, value) {
        let rawValue = this.rawValue;
        let deserializeValue = this.deserializeValue === undefined ? value => value : this.deserializeValue;
        this.update(object, propertyName, deserializeValue(value, rawValue));
      }

    }
  });

  FormFieldComponent.reopenClass({
    positionalParams: ['propertyName']
  });
  var _default = FormFieldComponent;
  _exports.default = _default;
});