define("ember-form-for/templates/components/form-fields/checkbox-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m/s9z2Ul",
    "block": "[[[6,[39,0],[[33,1]],[[\"tagName\",\"class\",\"classNames\",\"disabled\",\"errorClasses\",\"hintClasses\",\"inputClasses\",\"labelClasses\",\"object\",\"hint\",\"form\",\"label\"],[\"fieldset\",\"checkbox-group\",[33,2],[33,3],[33,4],[33,5],[33,6],[33,7],[33,8],[33,9],[33,10],[33,11]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,12],[[30,1,[\"labelText\"]]],[[\"required\",\"tagName\"],[[33,13],\"legend\"]]]],[1,\"\\n\\n  \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,15],[[28,[37,15],[[33,16]],null]],null],null,[[[1,\"      \"],[1,[28,[35,17],null,[[\"object\",\"propertyName\",\"option\",\"update\",\"disabled\",\"dir\",\"lang\",\"value\"],[[33,8],[33,1],[30,2],[28,[37,18],[[30,0],\"updateSelection\",[30,2]],null],[33,3],[33,19],[33,20],[30,2]]]]],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[1,[30,1,[\"errors\"]]],[1,\"\\n  \"],[1,[30,1,[\"hint\"]]],[1,\"\\n\"]],[1]]]]]],[\"f\",\"option\"],false,[\"form-field\",\"propertyName\",\"fieldClasses\",\"disabled\",\"errorClasses\",\"hintClasses\",\"inputClasses\",\"labelClasses\",\"object\",\"hint\",\"form\",\"label\",\"form-label\",\"required\",\"each\",\"-track-array\",\"options\",\"form-fields/checkbox-group/option\",\"action\",\"dir\",\"lang\"]]",
    "moduleName": "ember-form-for/templates/components/form-fields/checkbox-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});