define("ember-form-for/components/form-fields/text-field", ["exports", "@ember/component", "@ember/object", "ember-form-for/templates/components/form-fields/text-field"], function (_exports, _component, _object, _textField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const TextFieldComponent = _component.default.extend({
    tagName: '',
    layout: _textField.default,
    control: 'one-way-text',

    update(object, propertyName, value) {
      (0, _object.set)(object, propertyName, value);
    }

  });

  TextFieldComponent.reopenClass({
    positionalParams: ['propertyName']
  });
  var _default = TextFieldComponent;
  _exports.default = _default;
});