define("ember-form-for/helpers/form-for/humanize", ["exports", "@ember/component/helper", "ember-form-for/utils/strings"], function (_exports, _helper, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formForHumanize = formForHumanize;

  function formForHumanize(_ref) {
    let [string] = _ref;
    return (0, _strings.humanize)(string);
  }

  var _default = (0, _helper.helper)(formForHumanize);

  _exports.default = _default;
});