define("ember-validated-form/components/validated-input/hint", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <small
    class={{class-list
      (if (macroCondition (macroGetOwnConfig "isUikit")) "uk-text-muted")
      (if
        (macroCondition (macroGetOwnConfig "isBootstrap")) "form-text text-muted"
      )
    }}
    ...attributes
  >{{yield}}{{@hint}}</small>
  */
  {
    "id": "51dN8aB3",
    "block": "[[[11,\"small\"],[16,0,[28,[37,0],[\"uk-text-muted\",[27]],null]],[17,1],[12],[18,3,null],[1,[30,2]],[13]],[\"&attrs\",\"@hint\",\"&default\"],false,[\"class-list\",\"yield\"]]",
    "moduleName": "ember-validated-form/components/validated-input/hint.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});