define("ember-form-for/mixins/form-field", ["exports", "@ember/debug", "@ember/object", "@ember/object/internals", "@ember/object/mixin", "@ember/utils", "ember-form-for/utils/titlecase"], function (_exports, _debug, _object, _internals, _mixin, _utils, _titlecase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    instrumentDisplay: '{{form-field}}',

    didReceiveAttrs() {
      (false && !((0, _utils.typeOf)(this.propertyName) === 'string') && (0, _debug.assert)(`${this.instrumentDisplay} requires propertyName to be set`, (0, _utils.typeOf)(this.propertyName) === 'string'));
      let objectType = (0, _utils.typeOf)(this.object);
      (false && !(objectType === 'object' || objectType === 'instance') && (0, _debug.assert)(`${this.instrumentDisplay} requires object to be set`, objectType === 'object' || objectType === 'instance'));

      this._setupLabel();

      this._super(...arguments);
    },

    identifier: (0, _object.computed)('object', 'propertyName', 'value', function () {
      return this._identifier();
    }),

    _identifier() {
      return `${(0, _internals.guidFor)(this.object)}_${this.propertyName}`;
    },

    _setupLabel() {
      (0, _object.set)(this, 'label', this.label || (0, _titlecase.default)(this.propertyName));
    }

  });

  _exports.default = _default;
});