define("ember-form-for/components/fields-for", ["exports", "ember-form-for/templates/components/fields-for", "@ember/component", "@ember/object"], function (_exports, _fieldsFor, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const FieldsForComponent = _component.default.extend({
    layout: _fieldsFor.default,
    tagName: '',

    update(object, propertyName, value) {
      (0, _object.set)(object, propertyName, value);
    }

  });

  FieldsForComponent.reopenClass({
    positionalParams: ['object']
  });
  var _default = FieldsForComponent;
  _exports.default = _default;
});