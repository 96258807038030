define("ember-form-for/components/form-fields/checkbox-field", ["exports", "@ember/component", "@ember/object", "ember-form-for/templates/components/form-fields/checkbox-field"], function (_exports, _component, _object, _checkboxField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const CheckboxFieldComponent = _component.default.extend({
    tagName: '',
    layout: _checkboxField.default,
    control: 'one-way-checkbox',

    update(object, propertyName, value) {
      (0, _object.set)(object, propertyName, value);
    }

  });

  CheckboxFieldComponent.reopenClass({
    positionalParams: ['propertyName']
  });
  var _default = CheckboxFieldComponent;
  _exports.default = _default;
});