define("ember-form-for/helpers/is-equal", ["exports", "@ember/component/helper", "ember-form-for/utils/is-equal"], function (_exports, _helper, _isEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(_ref => {
    let [a, b] = _ref;
    return (0, _isEqual.default)(a, b);
  });

  _exports.default = _default;
});