define("ember-cli-meta-tags/templates/components/head-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+85Cjul4",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[1,[28,[35,3],null,[[\"headTag\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[\"headTag\"],false,[\"each\",\"-track-array\",\"headTags\",\"head-tag\"]]",
    "moduleName": "ember-cli-meta-tags/templates/components/head-tags.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});