define("ember-form-for/components/form-for", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/service", "@ember/utils", "ember-form-for/templates/components/form-for"], function (_exports, _component, _object, _runloop, _service, _utils, _formFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const FormForComponent = _component.default.extend({
    layout: _formFor.default,
    tagName: 'form',
    config: (0, _service.inject)('ember-form-for/config'),
    attributeBindings: ['tabindex', 'form:id', 'onsubmit'],

    init() {
      this._super(...arguments);

      let formClasses = (0, _object.get)(this, 'config.formClasses');
      let classNames = this.classNames;
      (0, _object.set)(this, 'classNames', (classNames || []).concat(formClasses));
      this.notifyPropertyChange();
    },

    onsubmit(event) {
      event.preventDefault(); // this.actions.submit.apply(this);
    },

    submit: object => object.save(),
    reset: object => object.rollback(),

    update(object, propertyName, value) {
      (0, _object.set)(object, propertyName, value);
    },

    handleErrors(object) {
      let errors = object.errors;

      if (errors) {
        for (let propertyName in errors) {
          if ((0, _utils.isPresent)((0, _object.get)(errors, propertyName))) {
            (0, _object.set)(this, 'tabindex', -1);
            (0, _runloop.schedule)('afterRender', () => {
              if (this.isDestroyed || this.isDestroying) {
                return;
              }

              this.element.focus();
            });
            break;
          }
        }
      }
    },

    actions: {
      submit(object) {
        let promise = this.submit(object);
        (0, _object.set)(this, 'tabindex', undefined);

        if (promise && typeof promise.finally === 'function') {
          promise.finally(() => {
            if (this.isDestroyed || this.isDestroying) {
              return;
            }

            this.handleErrors(object);
          });
        } else {
          this.handleErrors(object);
        }

        return promise;
      }

    }
  });

  FormForComponent.reopenClass({
    positionalParams: ['object']
  });
  var _default = FormForComponent;
  _exports.default = _default;
});