define("ember-place-autocomplete/services/google-place-autocomplete/manager", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    init() {
      this._super(...arguments);

      this.set('numberOfActiveAutoCompleteFields', 0);
    },

    /**
     * @description Increments the counter of active components.
     * Intended to be used everytime a new place-autocomplete-filed is
     * instanciated.
     */
    register() {
      this.incrementProperty('numberOfActiveAutoCompleteFields');
    },

    /**
     * @description Decrements the counter of active components.
     * Intended to be used everytime a new place-autocomplete-filed is
     * going to be destroyed.
     */
    unregister() {
      this.decrementProperty('numberOfActiveAutoCompleteFields');
    },

    /**
     * @description Cleanup DOM when ALL component instances of place-autocomplete-field
     * are removed from the DOM. If there are still components active, it does nothing.
     *
     * @returns { Boolean } - Indicates whether the DOM was cleaned or not.
     */
    removePlacesAutoCompleteContainersIfRequired() {
      if (!document || this.numberOfActiveAutoCompleteFields > 0) {
        return false;
      }

      const pacContainers = document.querySelectorAll('.pac-container');

      for (let index = 0; pacContainers.length > index; index++) {
        pacContainers[index].parentNode.removeChild(pacContainers[index]);
      }

      return true;
    }

  });

  _exports.default = _default;
});